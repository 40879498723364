/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


///////////
// `menu //
///////////
$('.toggle-menu').click(function() {
    $('.navTrigger').toggleClass('is-active');
    $('.app').toggleClass('has-overlay');
    $('.overlay').toggleClass('open');
    $('.search-field').focus();
});

$('.navTrigger.is-active').click(function() {
    $('.search-field').blur();
});

$('.overlay').click(function() {
    // body...
    $('.navTrigger').removeClass('is-active');
    $('.app').removeClass('has-overlay');
    $('.overlay.open').removeClass('open');

});

$('.searchBar').click(function() {
    return false
});
// $('.menu').click(function(){ return false });


///////////////////////////////
// `home sentences slider // //
///////////////////////////////

$('.slides').slick({
    dots: false,
    infinite: true,
    speed: 500,
    fade: true,
    autoplay: true,
    cssEase: 'linear'
});


// $('.pictures').slick({
//     dots: false,
//     infinite: true,
//     speed: 1000,
//     fade: true,
//     autoplay: true,
//     cssEase: 'linear',
//     arrows: 'false'
// });


// `scrollbar

// Form 
// 
$('.i_field').focus(function() {
    if ($(this).val() === 0) {
        $(this).next('label').removeClass('labelHide');
        $(this).next('label').removeClass('labelShow');
        $(this).next('label').addClass('labelBlur');
    }
});
$('.i_field').blur(function() {
    if ($(this).val() === 0) {
        $(this).next('label').addClass('labelShow');
    }
});
$('.i_field').keypress(function() {
    $(this).next('label').addClass('labelHide');
});

/**
 * Filter the portfolio
 * @return {[type]} [description]
 */
function portfolio_quicksand() {

    // Setting up our variables
    var $filter;
    var $container;
    var $containerClone;
    var $filterLink;
    var $filteredItems

    // Set our filter
    $filter = $('.filter li.active a').attr('class');

    // Set our filter link
    $filterLink = $('.filter li a');

    // Set our container
    $container = $('ul.filterable-grid');

    // Clone our container
    $containerClone = $container.clone();

    // Apply our Quicksand to work on a click function
    // for each of the filter li link elements
    $filterLink.click(function(e) {
        // Remove the active class
        $('.filter li').removeClass('active');

        // Split each of the filter elements and override our filter
        $filter = $(this).attr('class').split(' ');

        // Apply the 'active' class to the clicked link
        $(this).parent().addClass('active');

        // If 'all' is selected, display all elements
        // else output all items referenced by the data-type
        if ($filter == 'all') {
            $filteredItems = $containerClone.find('li');
        } else {
            $filteredItems = $containerClone.find('li[data-type~=' + $filter + ']');
        }

        // Finally call the Quicksand function
        $container.quicksand($filteredItems, {
            // The duration for the animation
            duration: 750,
            // The easing effect when animating
            easing: 'easeInOutCirc',
            // Height adjustment set to dynamic
            adjustHeight: 'dynamic'
        });
    });
}

if (jQuery().quicksand) {

    portfolio_quicksand();

}

/*direction aware hover effect*/
this.$el.on( 'mouseenter.hoverdir, mouseleave.hoverdir', function( event ) {
    
    var $el = $( this ),
        $hoverElem = $el.find( 'div' ),
        direction = self._getDir( $el, { x : event.pageX, y : event.pageY } ),
        styleCSS = self._getStyle( direction );
    
    if( event.type === 'mouseenter' ) {
        
        $hoverElem.hide().css( styleCSS.from );
        clearTimeout( self.tmhover );

        self.tmhover = setTimeout( function() {
            
            $hoverElem.show( 0, function() {
                
                var $el = $( this );
                if( self.support ) {
                    $el.css( 'transition', self.transitionProp );
                }
                self._applyAnimation( $el, styleCSS.to, self.options.speed );

            } );
            
        
        }, self.options.hoverDelay );
        
    }
    else {
    
        if( self.support ) {
            $hoverElem.css( 'transition', self.transitionProp );
        }
        clearTimeout( self.tmhover );
        self._applyAnimation( $hoverElem, styleCSS.from, self.options.speed );
        
    }
        
} );